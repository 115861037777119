import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 25px 35px;
  border-radius: 25px;
  border: 1px solid var(--White-Transparent-W-20, rgba(255, 255, 255, 0.2));
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  max-width: 560px;
  margin: 30px auto 100px auto;

  svg {
    flex: 0 0 56px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      margin: 0;
      padding: 0;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.2px;
    }
  }

  @media (max-width: 768px) {
    padding: 25px;
    flex-direction: column;
    margin: 30px auto 40px auto;

    div {
      text-align: center;
      p,
      a {
        font-size: 16px;
      }
    }
  }
`;
