import styled from "styled-components";
import { Container } from "@/shared/ui";

export const ButtonLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 40px 16px;

  h2 {
    margin: 0;
    padding: 0;
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.2px;
    text-align: center;
  }

  button {
    max-width: 350px;
    position: relative;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      visibility: hidden;
      z-index: 3;
    }
  }

  @media (max-width: 768px) and (orientation: portrait) {
    gap: 5px;
    position: sticky;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 70%);

    button {
      max-width: 300px;
    }
  }
`;
