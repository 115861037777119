'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import { Container } from '@/shared/ui';
import { LockIcon } from '@/shared/ui/Icon/svg';
import * as SC from './styled';

function UnblockGuideExternal() {
  const t = useTranslations();

  return (
    <Container>
      <SC.Wrapper>
        <LockIcon />
        <div>
          <p>{t('login.unblock.external_unblock_guide')}</p>
          <a target="_blank" rel="noreferrer" href="https://nicegram.app/articles/unblock">
            {t('login.unblock.external_unblock_guide_link_text')}
          </a>
        </div>
      </SC.Wrapper>
    </Container>
  );
}

export default UnblockGuideExternal;
