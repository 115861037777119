'use client';

import React from 'react';
import toast from 'react-hot-toast';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useAnalyticsContext } from '@/processes/analytics';
import { useUserInfoContext } from '@/processes/auth';
import { useDeviceInfoContext } from '@/processes/device-info';
import api from '@/shared/api';
import { Button } from '@/shared/ui';
import { useRouter } from '@/shared/lib/hooks';
import { PlaneIcon } from '@/shared/ui/Icon/svg';
import { TgWidgetUser } from '@/shared/lib/types';
import initTgWidget from '../lib/init-tg-widget';
import * as SC from './styled';

function LoginBlock() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const t = useTranslations();
  const { user, isFetching, fetchUser } = useUserInfoContext();
  const { sendSafeGtagEvent, sendSafeYMEvent } = useAnalyticsContext();
  const { isTelegramBot } = useDeviceInfoContext();

  const campaignUTM = searchParams.get('campaign');

  React.useEffect(() => {
    sendSafeGtagEvent('show_login');
    sendSafeYMEvent('login_show');
    initTgWidget();
  }, []);

  React.useEffect(() => {
    if (campaignUTM) {
      localStorage.setItem('campaign_utm', campaignUTM);
    }
  }, [campaignUTM]);

  React.useEffect(() => {
    if (!isFetching && user) {
      router.push('/settings');
    }
  }, [isFetching, user]);

  const handleLoginButtonClick = () => {
    const tgInitData = window.Telegram?.WebApp?.initData || '';

    if (tgInitData) {
      api.authService.auth
        .fetchPostLoginWithMiniApp(tgInitData)
        .then(fetchUser)
        .then(() => {
          toast.success(t('login.auth_success'));
          router.push('/settings');
        })
        .catch((e) => {
          toast.error(t('login.auth_error'));
          console.log(e);
        });
    } else {
      (window as any).Telegram.Login.auth(
        // todo: Move to env
        { bot_id: process.env.NEXT_PUBLIC_BOT_ID, request_access: true },
        (data: TgWidgetUser) => {
          if (!data) return;
          return api.authService.auth
            .fetchPostLogin(data)
            .then(fetchUser)
            .then(() => {
              toast.success(t('login.auth_success'));
              router.push('/settings');
            })
            .catch((e) => {
              toast.error(t('login.auth_error'));
              console.log(e);
            });
        },
      );
    }
  };

  return (
    <SC.Wrapper>
      <h2>{t('login.unblock.ready_to_unblock')}</h2>
      <Button
        id="tgLogin"
        onClick={handleLoginButtonClick}
        fullWidth
        label={
          <SC.ButtonLabelWrapper>
            <PlaneIcon />
            {t('login.unblock.login_with_tg')}
          </SC.ButtonLabelWrapper>
        }
      />
    </SC.Wrapper>
  );
}

export default LoginBlock;
